
import { insert, warn } from './../shared/utils';

'use strict'

// create template method
const createTemplate = (el) => {
  const { TEMPLATE } = fesdDB.video4
  const { childDom } = el
  const container = document.createElement('div')
  container.innerHTML = TEMPLATE();

  const content = container.querySelector('.player-wrapper');
  [...childDom].forEach((child) => {
    content.append(child);
  });

  return container.children[0];
};

// class
class videoPlayer extends HTMLElement {
  constructor() {
    super();

    this.#create();
  }

  #create() {
    if (!this.getAttribute('video-id')) {
      warn('videoPlayer', `video-render needs a ['video-id'] attribute to creat player.`);
      return;
    }

    this.videoId = this.getAttribute('video-id');
    this.videoType = this.getAttribute('video-type');
    this.autoplay = this.getAttribute('video-autoplay');

    this.#mount();
  }

  #mount() {
    this.childDom = this.childNodes;
    this.template = createTemplate(this);

    // 清空原本結構
    this.innerHTML = '';
    // 放置整理過後的結構
    this.append(this.template);

    this.#init();
  }

  #init() {
    const { videoType } = this;
    let layout = '';

    switch (videoType) {
      case 'youtubeAPI':
        // this.#youtubeAPI();
        break;
      case 'youtube':
        layout = this.#youtube();
        break;
      case 'youkuAPI':
        // this.#youkuAPI();
        break;
      case 'youku':
        layout = this.#youku();
        break;
      case 'vimeo':
        layout = this.#vimeo();
        break;
      case 'bilibili':
        layout = this.#bilibili();
        break;
      case 'videojs':
        // this.#videoJs();
        break;
      default:
        break;
    }

    this.querySelector('.player-wrapper').insertAdjacentHTML(insert.prepend, layout);
  }

  // youtube iframe
  #youtube() {
    const { videoId, autoplay } = this;
    return `<iframe src="https://www.youtube.com/embed/${videoId}?rel=0&${
      autoplay === 'on' ? 'autoplay=1' : ''
    }&mute=1&loop=1&enablejsapi=1" frameborder="0" allowfullscreen="0" volumn="0" allow="${
      autoplay === 'on' ? 'autoplay;' : ''
    } encrypted-media; gyroscope; picture-in-picture;"></iframe>`;
  }

  // vimeo iframe
  #vimeo() {
    const { videoId, autoplay, hash } = this;

    return `<iframe src="https://player.vimeo.com/video/${videoId}?h=${hash}&${
      autoplay === 'on' ? 'autoplay=1' : ''
    }&loop=1&color=ffffff&title=0&byline=0&portrait=0" frameborder="0" allow="${
      autoplay === 'on' ? 'autoplay;' : ''
    } fullscreen; picture-in-picture" allowfullscreen></iframe><script src="https://player.vimeo.com/api/player.js"></script>`;
  }

  // youku iframe
  #youku() {
    const { videoId, autoplay } = this;

    return `<iframe src="https://player.youku.com/embed/${videoId}?rel=0&${
      autoplay === 'on' ? 'autoplay=1' : ''
    }" frameborder=0 "allowfullscreen"></iframe>`;
  }

  #bilibili() {
    const { videoId, autoplay } = this;
    return `<iframe src="//player.bilibili.com/player.html?bvid=${videoId}&page=1&as_wide=1&high_quality=1&danmaku=0" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>`
  }

  play() {
    const { videoType } = this;

    switch (videoType) {
      case 'youtubeAPI':
        // 無可用 functione
        break;
      case 'youtube':
        this.querySelector('iframe').contentWindow.postMessage(
          JSON.stringify({ event: 'command', func: 'playVideo' }),
          '*'
        );
        break;
      case 'youkuAPI':
        // 無可用 functione
        break;
      case 'youku':
        // 無可用 functione
        break;
      case 'vimeo':
        this.querySelector('iframe').contentWindow.postMessage(
          JSON.stringify({ value: 'true', method: 'play' }),
          '*'
        );
        break;
      case 'bilibili':
        // 無可用 functione
        break;
      case 'videojs':
        // 無可用 functione
        break;
      default:
        break;
    }
  }

  pause() {
    const { videoType } = this;

    switch (videoType) {
      case 'youtubeAPI':
        // 無可用 functione
        break;
      case 'youtube':
        this.querySelector('iframe').contentWindow.postMessage(
          JSON.stringify({ event: 'command', func: 'pauseVideo' }),
          '*'
        );
        break;
      case 'youkuAPI':
        // 無可用 functione
        break;
      case 'youku':
        // 無可用 functione
        break;
      case 'vimeo':
        this.querySelector('iframe').contentWindow.postMessage(
          JSON.stringify({ value: 'true', method: 'pause' }),
          '*'
        );
        break;
      case 'bilibili':
        // 無可用 functione
        break;
      case 'videojs':
        // 無可用 functione
        break;
      default:
        break;
    }
  }
}

export default videoPlayer;
