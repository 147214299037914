// import Swiper bundle with all modules installed
import { Swiper as SwiperV8 } from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';
import { isString, isNodeList, isElementExist, getAllElements, createUid } from './../shared/utils';

window.Swiper = SwiperV8;
('use strict');

// 設置 CSS 樣式
const setCss = (target, style, css) => {
  if (!target) return;

  // 若是 NodeList 則需使用 foreach set css
  if (isNodeList(target)) target.forEach(el => (el.style[style] = css));
  else target.style[style] = css;
};

class Article4 {
  constructor(el, options = {}) {
    if (!isString(el) || !isElementExist(el)) return;

    this.__storage__ = {
      el,
      options,
    };

    this.#create();
  }

  #create() {
    const { el, options } = this.__storage__;
    const { SETTINGS } = fesdDB.article4;

    this.elements = getAllElements(el);
    this.options = Object.assign({}, SETTINGS, options);

    this.#init();
  }

  #init() {
    const { elements } = this;

    elements.forEach(parent => {
      parent.querySelectorAll('._article').forEach(el => {
        el.article = {};
        el.article.parent = parent;
        el.article.params = this.#getArticleData(el);

        this.#setStyle(el).#createVideo4(el).#createSwiper(el);
      });
    });
  }

  // 取得元件上的設定值
  #getArticleData(element) {
    return {
      // 元件本身
      $selector: element,

      // 父層元件
      $backgroundWrap: element.querySelector('._backgroundWrap'),
      $contentWrap: element.querySelector('._contentWrap'),
      $wordCover: element.querySelector('._wordCover'),
      $buttonCover: element.querySelector('._buttonCover'),
      $imgCover: element.querySelector('._imgCover'),
      $cover: element.querySelectorAll('._cover'),
      $swiper: element.querySelector('.swiper'),
      $swiperButtonCover: element.querySelector('.swiper-button-cover'),

      // 子層元件
      $h: element.querySelector('._H'),
      $subH: element.querySelector('._subH'),
      $p: element.querySelector('._P'),
      $button: element.querySelector('._button'),
      $description: element.querySelectorAll('._description'),
      $video: element.querySelectorAll('[video-id]'),

      // 父層設定
      typeFullColor: element.getAttribute('typeFull-color'),
      typeFullBoxColor: element.getAttribute('typeFull-boxcolor'),

      // 子層設定
      hColor: element.getAttribute('h-color'),
      subHColor: element.getAttribute('subh-color'),
      pColor: element.getAttribute('p-color'),
      buttonColor: element.getAttribute('button-color'),
      buttonColorHover: element.getAttribute('button-color-hover'),
      buttonTextColor: element.getAttribute('button-textcolor'),
      descriptionColor: element.getAttribute('description-color'),
    };
  }

  // 設置設定值的 CSS 樣式
  #setStyle(element) {
    const { params } = element.article;

    // article ._H
    setCss(params.$h, 'color', params.hColor);

    // article ._subH
    setCss(params.$subH, 'color', params.subHColor);

    // article ._P
    setCss(params.$p, 'color', params.pColor);

    // imgCover ._description
    setCss(params.$description, 'color', params.descriptionColor);

    // button background-color && text color
    setCss(params.$button, 'backgroundColor', params.buttonColor);
    setCss(params.$button, 'color', params.buttonTextColor);

    // button hover color
    if (params.buttonColorHover) {
      params.$button.appendChild(document.createElement('span'));
      setCss(params.$button.querySelector('span'), 'backgroundColor', params.buttonColorHover);
    }

    // typeFull background-color
    setCss(params.$backgroundWrap, 'backgroundColor', params.typeFullColor);

    // typeFull box background-color
    setCss(params.$contentWrap, 'backgroundColor', params.typeFullBoxColor);

    return this;
  }

  #createVideo4(element) {
    const { params } = element.article;

    if (params.$video.length) {
      const video = new Video4(params.$video);
    }

    return this;
  }

  #createSwiper(element) {
    const { basic_rwd } = this.options;

    const { params } = element.article;

    // 判斷是否擁有 swiper4 結構
    if (!params.$swiper) return;

    // set id
    const $id = createUid();

    const $this = this;

    // set swiper
    let $swiperSet = {
      on: {
        init(swiper) {
          const container = swiper.$el[0];
          // video4 重複綁定修正
          if (params.$video.length) {
            // 應該抓 DB 設定的 classname
            const allVideo4 = container.querySelectorAll('[video4-active]');
            [...allVideo4].forEach(element => {
              element.querySelector(fesdDB.video4.SETTINGS.videoButton)?.remove();
              element.querySelector('.overlay')?.remove();
              element.removeAttribute('video4-active');
            });
            $this.update();
          }
        },
        observerUpdate(swiper) {
          if (swiper.params.autoplay.enabled) {
            swiper.autoplay.start();
            swiper.update();
          }
        },
      },
      observer: true,
      breakpoints: {},
    };

    params.$selector.setAttribute('img-swiper', 'on');
    params.$selector.classList.add(`swiper-${$id}`);

    // swiper navigation (預設為 off)
    if (params.$selector.getAttribute('swiper-arrow') !== 'off' && params.$selector.getAttribute('swiper-arrow')) {
      const next = document.createElement('div');
      next.className = `swiper-button-next swiper-${$id}`;
      params.$swiperButtonCover.appendChild(next);

      const prev = document.createElement('div');
      prev.className = `swiper-button-prev swiper-${$id}`;
      params.$swiperButtonCover.appendChild(prev);

      $swiperSet.navigation = {
        nextEl: `.swiper-button-next.swiper-${$id}`,
        prevEl: `.swiper-button-prev.swiper-${$id}`,
      };
    }

    // swiper pagination (預設為 on)
    if (params.$selector.getAttribute('swiper-nav') !== 'off' || !params.$selector.getAttribute('swiper-nav')) {
      const pagination = document.createElement('div');
      pagination.className = `swiper-pagination swiper-${$id}`;
      params.$swiper.appendChild(pagination);

      $swiperSet.pagination = {
        el: `.swiper-pagination.swiper-${$id}`,
        clickable: true,
      };
    }

    // slidesPerView
    if (Number.parseInt(params.$selector.getAttribute('swiper-num'))) {
      $swiperSet.breakpoints[basic_rwd] = {
        slidesPerView: params.$selector.getAttribute('swiper-num') ? (Number.parseInt(params.$selector.getAttribute('swiper-num')) > 5 ? 5 : Number.parseInt(params.$selector.getAttribute('swiper-num'))) : 1,
      };
    }

    // autoplay (預設為 on)
    if (params.$selector.getAttribute('swiper-autoplay') !== 'off' || !params.$selector.getAttribute('swiper-autoplay')) {
      $swiperSet.autoplay = {
        delay: 3000,
        disableOnInteraction: false,
      };
    }

    // loop (預設為 on)
    if (params.$selector.getAttribute('swiper-loop') !== 'off' || !params.$selector.getAttribute('swiper-loop')) {
      $swiperSet.loop = true;
    }

    // speed
    if (params.$selector.getAttribute('swiper-speed')) {
      $swiperSet.speed = parseInt(params.$selector.getAttribute('swiper-speed'));
    }

    // parallax (預設為 off)
    if (params.$selector.getAttribute('swiper-parallax') !== 'off' && !params.$selector.getAttribute('swiper-loop')) {
      $swiperSet.parallax = true;
    }

    // pagination dynamicBullets (預設為 off)
    if (params.$selector.getAttribute('swiper-pagination-dynamic') === 'on') {
      $swiperSet.pagination.dynamicBullets = true;
    }

    // 若 swiper 只有一筆輪播則隱藏 navigation 及 pagination
    let gate = () => (window.innerWidth > this.basic_rwd ? Number(params.$selector.getAttribute('swiper-num')) || 1 : 1);

    if (params.$swiper.querySelectorAll('.swiper-slide').length <= gate()) {
      $swiperSet.navigation = false;
      $swiperSet.pagination = false;
      $swiperSet.autoplay = false;
      $swiperSet.loop = false;

      params.$selector.querySelector('.swiper-button-cover').style.display = 'none';
      params.$selector.querySelector('.swiper-pagination').style.display = 'none';

      params.$swiper.classList.add('swiper-no-swiping');
    }

    const $swiper = new SwiperV8(params.$swiper, $swiperSet);

    element.article.swiperList = [];
    element.article.swiperList.push($swiper);

    return this;
  }
  update() {
    const { elements } = this;

    elements.forEach(parent => {
      parent.querySelectorAll('._article').forEach(el => {
        el.article = {};
        el.article.parent = parent;
        el.article.params = this.#getArticleData(el);

        this.#createVideo4(el);
      });
    });
  }
}

export default Article4;
