import videoPlayer from './videoPlayer';
import SHARED from './../shared/shared';

import { isString, isElement, isNodeList, getAllElements, warn, error, insert } from './../shared/utils';

('use strict');

// define custom element
if (!customElements.get('video-player')) {
  customElements.define('video-player', videoPlayer);
}

// 判斷影片物件有沒有 video-id
const hasVideoID = data => {
  return data.videoId !== '' || typeof data.videoId !== 'undefined' ? true : false;
};

// 判斷影片物件是否已經啟用
const isActive = data => {
  return data.$selector.getAttribute('video4-active') === 'on' ? true : false;
};

const createPlayer = params => {
  return `<video-player video-id="${params.videoId}" video-type="${params.videoType}" video-autoplay="${params.videoAutoplay}"></video-player>`;
};

class video4 {
  constructor(el, options = {}) {
    // 可傳 string 或 element 或 nodeList
    if (!isString(el) && !isElement(el) && !isNodeList(el)) {
      error('video4', `找不到該物件 -> ${el}`);
      return;
    }

    this.__storage__ = {
      el,
      options,
    };

    this.#create();
  }

  #create() {
    const { el, options } = this.__storage__;
    // 從 fesdDB 提取設定
    const { SETTINGS, EVENTS } = fesdDB.video4;

    this.elements = getAllElements(el);
    this.options = Object.assign({}, SETTINGS, options);
    this.__events__ = Object.assign({}, EVENTS);

    if (this.options.on) {
      for (const [k, v] of Object.entries(this.options.on)) {
        this.__events__[k] = [v];
      }
    }

    this.#init();
  }

  #init() {
    const { elements, options } = this;

    elements.forEach(el => {
      el.video = {};
      el.video.instance = this;
      el.video.defaultOptions = options;
      el.video.params = this.#getVideoData(el);

      if (hasVideoID(el.video.params) && !isActive(el.video.params)) {
        el.setAttribute('video4-active', 'on');
        this.#createCover(el);
      }

      if (!hasVideoID(el.video.params)) {
        error('video4', `無法取得影片 ID`);
      }
    });

    this.emit('init');
  }

  #createCover(el) {
    const { LAYOUT } = fesdDB.video4;
    const params = el.video.params;
    const { $selector, videoLayoutNo, videoId, videoType, videoMode, videoButton, videoCover } = params;

    if (videoMode === 'onBox') {
      let $target = null;
      if (videoCover === 'on') {
        el.classList.add('video4-cover');
        // 無圖片結構放置圖片結構
        if (!$selector.querySelector('img')) {
          $selector.insertAdjacentHTML(insert.prepend, '<picture><source srcset="" type="image/webp"><source srcset="" type="image/jpeg"><img src="" alt></picture>');
          warn('video4', `若啟用 [videoCover] 且選擇 onBox 模式必須於 video-target 內放置 img 結構，若無結構則自動加入圖片結構`);
        }

        const $cover = $selector.querySelector('img');
        $cover.insertAdjacentHTML(insert.after, LAYOUT[videoLayoutNo]);

        const src = $cover?.getAttribute('src') || $cover?.getAttribute('data-src');

        // 無圖片時放置預設封面畫面，僅 youtube & vimeo 提供
        if (!src && videoType == 'youtube') {
          $cover.setAttribute('src', `https://img.youtube.com/vi/${videoId}/0.jpg`);
        } else if (!src && videoType == 'vimeo') {
          $cover.setAttribute('src', `https://vumbnail.com/${videoId}_large.jpg`);
        } else {
          warn('video4', `僅 youtube & vimeo 提供，無圖片時放置預設封面畫面，其他影片平台請自行上傳封面照片`);
        }
      }

      // 若無設定撥放按鈕，則點擊物件轉移為物件本身
      if (videoButton == 'off') {
        $target = $selector;
      } else {
        $target = $selector.querySelector(videoButton) ?? $selector;
        if (!$selector.querySelector(videoButton)) {
          warn('video4', `找不到 videoButton 設定的 element -> '${videoButton}' , 點擊物件轉移至 '${this.__storage__.el}'`);
        }
      }

      if (!$target.video) {
        $target.video = {};
        $target.video.params = params;
      }

      $target.video.eventHandler = this.#trigger;
      $target.addEventListener('click', $target.video.eventHandler);
    } else if (videoMode === 'onPage') {
      let $target = null;
      if (videoCover == 'on') {
        el.classList.add('video4-cover');
        // 無圖片結構放置圖片結構
        if (!$selector.querySelector('img')) {
          $selector.insertAdjacentHTML(insert.prepend, '<picture><source srcset="" type="image/webp"><source srcset="" type="image/jpeg"><img src="" alt></picture>');
          warn('video', `若啟用 [videoCover] 且選擇 onBox 模式必須於 video-target 內放置 img 結構`);
        }

        const $cover = $selector.querySelector('img');
        $cover.insertAdjacentHTML(insert.after, LAYOUT[videoLayoutNo]);

        const src = $cover?.getAttribute('src') || $cover?.getAttribute('data-src');

        // 無圖片時放置預設封面畫面，僅 youtube 提供
        if (!src && videoType == 'youtube') {
          $cover.setAttribute('src', `https://img.youtube.com/vi/${videoId}/0.jpg`);
        } else if (!src && videoType == 'vimeo') {
          $cover.setAttribute('src', `https://vumbnail.com/${videoId}_large.jpg`);
        } else if (!src) {
          warn('video4', `僅 youtube & vimeo 提供，無圖片時放置預設封面畫面，其他影片平台請自行上傳封面照片`);
        }

        // 若無設定撥放按鈕，則點擊物件轉移為物件本身
        if (videoButton == 'off') {
          $target = $selector;
        } else {
          $target = $selector.querySelector(videoButton) ?? $selector;
          if (!$selector.querySelector(videoButton)) {
            warn('video4', `找不到 videoButton 設定的 element -> '${videoButton}', 點擊物件轉移至 '${this.__storage__.el}'`);
          }
        }
      } else {
        $selector.innerHTML = createPlayer(params);
        return;
      }

      if (!$target.video) {
        $target.video = {};
        $target.video.params = params;
      }

      $target.video.eventHandler = this.#trigger;
      $target.addEventListener('click', $target.video.eventHandler);
    }
  }

  #trigger(e) {
    const { video } = this;
    const { $selector, videoMode, videoTarget, videoTargetRoute } = video.params;

    if (videoMode == 'onBox') {
      const options = {
        target: videoTarget,
        route: videoTargetRoute,
        on: {
          complete(modal) {
            modal.querySelector('.modal-content').insertAdjacentHTML('beforeend', createPlayer(video.params));
          },
          open(modal) {
            const scrollers = modal.querySelectorAll('[data-overlayscrollbars-viewport]');
            _g.lazy.update();
            _g.scrollLock([...scrollers]);
          },
          close(modal) {
            const scrollers = modal.querySelectorAll('[data-overlayscrollbars-viewport]');
            _g.scrollUnlock([...scrollers]);
          },
          destroy(modal) {},
        },
      };

      Modal4.open(options);
    } else if (videoMode == 'onPage') {
      // onPage 點擊後應自動撥放影片
      video.params.videoAutoplay = 'on';
      // 直接替換 cover & playButton
      $selector.innerHTML = createPlayer(video.params);
    }
  }

  #getVideoData(el) {
    const { SETTINGS } = fesdDB.video4;

    return {
      $selector: el,
      videoId: el.getAttribute('video-id'),
      videoType: el.getAttribute('video-type'),
      videoAutoplay: el.getAttribute('video-autoplay') || SETTINGS.videoAutoplay,
      videoMode: el.getAttribute('video-mode') || SETTINGS.videoMode,
      videoButton: el.getAttribute('video-button') || SETTINGS.videoButton,
      videoCover: el.getAttribute('video-cover') || SETTINGS.videoCover,
      videoLayoutNo: el.getAttribute('video-layout-no') || SETTINGS.videoLayoutNo,
      videoTarget: el.getAttribute('video-target') || SETTINGS.videoTarget,
      videoTargetRoute: el.getAttribute('video-target-route') || SETTINGS.videoTargetRoute,
    };
  }

  update() {
    this.#create();
  }
}

Object.assign(video4.prototype, SHARED);

export default video4;
