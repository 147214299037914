
'use strict';

const userAgent = navigator.userAgent;

// isBrowser4
fesdDB.library.browser = {
  // Firefox 1.0+
  'isFirefox': typeof InstallTrigger !== 'undefined',
  // Opera 8.0+
  'isOpera': (!!window.opr && !!opr.addons) || !!window.opera || userAgent.indexOf(' OPR/') >= 0,
  // Internet Explorer 6-11
  'isIE': /*@cc_on!@*/ false || !!document.documentMode,
  // Edge 20+
  'isEdge': !(false || !!document.documentMode) && !!window.StyleMedia,
  // Edge (based on chromium) detection
  'isEdgeChromium': /\sedg\//i.test(userAgent) || /edg([ea]|ios)/i.test(userAgent),
  // Safari 3.0+ "[object HTMLElementConstructor]"
  'isSafari': !/chrome|crios|crmo/i.test(userAgent) && /safari/i.test(userAgent),
  // Chrome 1 - 79
  'isChrome': (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || /chrome|crios|crmo/i.test(userAgent)
}

// isMobile4
fesdDB.library.mobile = {
  // Android
  'isAndroid': userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1,
  // iOS | ipad
  'isiOS': userAgent.indexOf('Mac') > -1 && 'ontouchend' in document,
}

// isOs4
fesdDB.library.os = {
  // window
  'isWindows': userAgent.indexOf('Win') > -1,
  // macos
  'isMacOS': userAgent.indexOf('Mac') > -1,
  // UNIX
  'isUNIX': userAgent.indexOf('X11') > -1,
  // Linux
  'isLinux': userAgent.indexOf('Linux') > -1
}

// **eric - 以下待優化
const isBrowser4 = () => {
  const { browser } = fesdDB.library
  return Object.keys(browser).find(key => {
    if (browser[key]) return browser[key]
  })
}

const isMobile4 = () => {
  const { mobile } = fesdDB.library
  return Object.keys(mobile).some(key => {
    if (mobile[key]) return mobile[key] 
  })
}

const isOs4 = () => {
  const { os } = fesdDB.library
  return Object.keys(os).find(key => {
    if (os[key]) return os[key] 
  })
}

fesdDB.is.isBrowser4 = isBrowser4();
fesdDB.is.isMobile4 = isMobile4();
fesdDB.is.isOs4 = isOs4();
