import { OverlayScrollbars } from 'overlayscrollbars';

// FESD
// Detect4
import './fesd/detect4/detect4';
// Anchor4
import Anchor4 from './fesd/anchor4/anchor4';
// Modal4
import Modal4 from './fesd/modal4/modal4';
import './fesd/modal4/_modal4.sass';
// Aost4
import Aost4 from './fesd/aost4/aost4';
import './fesd/aost4/_aost4.sass';
// Article4
import Article4 from './fesd/article4/article4';
// Video4
import Video4 from './fesd/video4/video4';
import './fesd/video4/_video4.sass';
// Share4
import Share4 from './fesd/share4/share4';
// Dropdown4
import './fesd/dropdown4/dropdown4';
import './fesd/dropdown4/_dropdown4.sass';
// Marquee4
import './fesd/marquee4/marquee4';
import './fesd/marquee4/_marquee4.sass';
// multipurpose4
import './fesd/multipurpose4/multipurpose4';
import './fesd/multipurpose4/_multipurpose4.sass';
// Tab4
import './fesd/tab4/tab4';
import './fesd/tab4/_tab4.sass';
// Collapse4
import Collapse4 from './fesd/collapse4/collapse4';
// image-validate
import ImageValidate from './fesd/image-validate/image-validate';
import './fesd/image-validate/_image-validate.sass';
// category-slider
import CategorySlider from './fesd/category-slider/category-slider';
import './fesd/category-slider/_category-slider.sass';
// image-preview
import ImagePreview from './fesd/image-preview/image-preview';
import './fesd/image-preview/_image-preview.sass';

window.OverlayScrollbars = OverlayScrollbars;
window.Anchor4 = Anchor4;
window.Aost4 = Aost4;
window.Modal4 = Modal4;
window.Video4 = Video4;
window.Article4 = Article4;
window.Collapse4 = Collapse4;
window.Share4 = Share4;
window.ImageValidate = ImageValidate;
window.CategorySlider = CategorySlider;
window.ImagePreview = ImagePreview;
