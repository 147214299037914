'use strict';

// 獲取標籤上斷點設定
function getBreakpoints(m4) {
  const breakpoints = {};
  [...m4.attributes].forEach(attr => {
    if (attr.name.includes('duration-')) {
      const key = attr.name.replace('duration-', '');
      const value = attr.nodeValue;
      breakpoints[key] = Number(value);
    }
  });
  return Object.keys(breakpoints).length === 0 ? null : breakpoints;
}

// 透過標籤上設定的斷點判斷持續時間
function getDuration(m4) {
  if (getBreakpoints(m4)) {
    let breakpoint;
    const points = Object.keys(getBreakpoints(m4)).map(point => {
      return {
        value: point,
        point,
      };
    });
    points.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
    for (let i = 0; i < points.length; i += 1) {
      const { point, value } = points[i];
      if (window.matchMedia(`(max-width: ${value}px)`).matches) {
        breakpoint = point;
      }
    }
    return breakpoint ? Number(getBreakpoints(m4)[breakpoint]) : m4.s.options.duration;
  } else {
    return m4.s.options.duration;
  }
}

// 產生動畫結構
function createAnimateWrap(m4) {
  const { behavior, continual, gap } = m4.s.options;
  const childDOM = m4.childNodes;
  const container = document.createElement('div');
  const animateItem = document.createElement('div');
  container.className = 'animate-container';
  animateItem.className = 'animate-item';
  [...childDOM].forEach(element => {
    animateItem.append(element);
  });
  function cloneElement() {
    const cloneAnimateWrap = animateItem.cloneNode(true);
    cloneAnimateWrap.classList.add('clone');
    m4.s.cloneAnimation = null;
    m4.s.cloneAnimateEl = cloneAnimateWrap;
    return cloneAnimateWrap;
  }
  m4.s.animation = null;
  m4.s.animateEl = animateItem;
  m4.textContent = '';
  container.append(animateItem);
  m4.append(container);
  if (animateItem.clientWidth * 2 + gap >= m4.clientWidth) {
    if (behavior === 'normal' && continual) {
      container.append(cloneElement());
    }
  } else {
    m4.s.options.continual = false;
  }
}

// 設定動畫影格
function keyframes(m4) {
  const { behavior, direction, continual } = m4.s.options;
  let framesObj = {};
  // 判斷模式
  switch (behavior) {
    case 'normal':
      // 判斷方向
      // normal 模式會判斷是否開啟 continual
      let animateFrom, animateTo;
      switch (direction) {
        case 'top':
          animateFrom = continual ? 'translate3d(0,100%,0)' : `translate3d(0,${m4.clientHeight}px,0)`;
          animateTo = continual ? 'translate3d(0,-100%,0)' : 'translate3d(0,-100%,0)';
          framesObj.animate1 = [{ transform: animateFrom }, { transform: animateTo }];
          if (continual) {
            framesObj.animate2 = [{ transform: 'translate3d(0,0,0)' }, { transform: 'translate3d(0,-200%,0)' }];
          }
          break;
        case 'right':
          animateFrom = continual ? 'translate3d(0,0,0)' : 'translate3d(-100%,0,0)';
          animateTo = continual ? 'translate3d(200%,0,0)' : `translate3d(${m4.clientWidth}px,0,0)`;
          framesObj.animate1 = [{ transform: animateFrom }, { transform: animateTo }];
          if (continual) {
            framesObj.animate2 = [{ transform: 'translate3d(-100%,0,0)' }, { transform: 'translate3d(100%,0,0)' }];
          }
          break;
        case 'bottom':
          animateFrom = continual ? 'translate3d(0,-100%,0)' : 'translate3d(0,-100%,0)';
          animateTo = continual ? 'translate3d(0,100%,0)' : `translate3d(0,${m4.clientHeight}px,0)`;
          framesObj.animate1 = [{ transform: animateFrom }, { transform: animateTo }];
          if (continual) {
            framesObj.animate2 = [{ transform: 'translate3d(0,-200%,0)' }, { transform: 'translate3d(0,0,0)' }];
          }
          break;
        case 'left':
          animateFrom = continual ? 'translate3d(100%,0,0)' : `translate3d(${m4.clientWidth}px,0,0)`;
          animateTo = continual ? 'translate3d(-100%,0,0)' : 'translate3d(-100%,0,0)';
          framesObj.animate1 = [{ transform: animateFrom }, { transform: animateTo }];
          if (continual) {
            framesObj.animate2 = [{ transform: 'translate3d(0,0,0)' }, { transform: 'translate3d(-200%,0,0)' }];
          }
          break;
      }
      break;
    // 來回模式
    case 'alternate':
      // 判斷方向
      switch (direction) {
        case 'top':
          framesObj.animate1 = [{ transform: `translate3d(0,calc(${m4.clientHeight}px - 100%),0)` }, { transform: `translate3d(0,0,0)` }, { transform: `translate3d(0,calc(${m4.clientHeight}px - 100%),0)` }];
          break;
        case 'right':
          framesObj.animate1 = [{ transform: `translate3d(0,0,0)` }, { transform: `translate3d(calc(-100% + ${m4.clientWidth}px),0,0)` }, { transform: `translate3d(0,0,0)` }];
          break;
        case 'bottom':
          framesObj.animate1 = [{ transform: `translate3d(0,0,0)` }, { transform: `translate3d(0,calc(${m4.clientHeight}px - 100%),0)` }, { transform: `translate3d(0,0,0)` }];
          break;
        case 'left':
          framesObj.animate1 = [{ transform: `translate3d(calc(-100% + ${m4.clientWidth}px),0,0)` }, { transform: `translate3d(0,0,0)` }, { transform: `translate3d(calc(-100% + ${m4.clientWidth}px),0,0)` }];
          break;
      }
      break;
    case 'endStop':
      // 判斷方向
      switch (direction) {
        case 'top':
          framesObj.animate1 = [{ transform: `translate3d(0,${m4.clientHeight}px,0)` }, { transform: `translate3d(0,0,0)` }];
          break;
        case 'right':
          framesObj.animate1 = [{ transform: `translate3d(-100%,0,0)` }, { transform: `translate3d(0,0,0)` }];
          break;
        case 'bottom':
          framesObj.animate1 = [{ transform: `translate3d(0,-100%,0)` }, { transform: `translate3d(0,calc(${m4.clientHeight}px - 100%),0)` }];
          break;
        case 'left':
          framesObj.animate1 = [{ transform: `translate3d(${m4.clientWidth}px,0,0)` }, { transform: `translate3d(calc(${m4.clientWidth}px - 100%),0,0)` }];
          break;
      }
      break;
  }
  return framesObj;
}

function debounce(func) {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 200, event);
  };
}

class Marquee4 extends HTMLElement {
  constructor() {
    super();
    this.initialize = false;
  }
  static get observedAttributes() {
    return [];
  }
  attributeChangedCallback(attr, oldVal, newVal) {}
  connectedCallback() {
    if (this.initialize) return;
    this.initialize = true;
    this.#create();
  }
  #create() {
    const options = {
      direction: this.getAttribute('direction') || fesdDB.marquee4.SETTINGS.direction, // up / down / left / right
      behavior: this.getAttribute('behavior') || fesdDB.marquee4.SETTINGS.behavior, // normal / alternate / endStop
      duration: Number(this.getAttribute('duration')) || fesdDB.marquee4.SETTINGS.duration, // ms
      durationBreakpoints: getBreakpoints(this) || fesdDB.marquee4.SETTINGS.durationBreakpoints,
      autoplay: this.getAttribute('autoplay') || fesdDB.marquee4.SETTINGS.autoplay, // normal / alternate / endStop
      pauseOnMouseenter: this.getAttribute('pauseOnMouseEnter') ? (this.getAttribute('pauseOnMouseEnter') === 'true' ? true : false) : fesdDB.marquee4.SETTINGS.pauseOnMouseenter, // true / false
      continual: this.getAttribute('continual') ? (this.getAttribute('continual') === 'true' ? true : false) : fesdDB.marquee4.SETTINGS.continual, // true / false
      gap: Number(this.getAttribute('gap')) || fesdDB.marquee4.SETTINGS.gap,
    };
    this.s = {};
    this.s.options = options;
    this.s.nowDuration = getDuration(this);
    createAnimateWrap(this);
    this.#init();
  }
  #init() {
    const { direction, continual, gap } = this.s.options;
    // 方向為左/右
    switch (direction) {
      case 'left':
      case 'right':
        this.style.cssText = `--continual-gap: ${gap}px;`;
        break;
      case 'top':
      case 'bottom':
        if (continual) {
          this.style.cssText = `height: ${this.s.animateEl.clientHeight}px;--continual-gap: ${gap}px;`;
        } else {
          this.style.cssText = `min-height: ${this.s.animateEl.clientHeight}px;--continual-gap: ${gap}px;`;
        }
        break;
    }
    this.#animation();
    this.#event();
    if (continual) {
      this.classList.add(`continual`);
    }
    this.classList.add('m4-initialize');
  }
  #animation() {
    const m4 = this;
    const { direction, behavior, duration, autoplay, pauseOnMouseenter, continual } = m4.s.options;
    let playDelay; //跑馬燈自動撥放開始前的延遲時間
    function animateInit() {
      // 判斷模式
      switch (behavior) {
        case 'normal':
          m4.s.animateEl.style.transform = `${keyframes(m4).animate1[0].transform}`;
          m4.s.animation = m4.s.animateEl.animate(keyframes(m4).animate1, {
            duration: m4.s.nowDuration,
            iterations: Infinity,
          });
          if (continual) {
            m4.s.cloneAnimateEl.style.transform = `${keyframes(m4).animate2[0].transform}`;
            m4.s.cloneAnimation = m4.s.cloneAnimateEl.animate(keyframes(m4).animate2, {
              duration: m4.s.nowDuration,
              delay: -m4.s.nowDuration / 2,
              iterations: Infinity,
            });
          }
          break;
        case 'alternate':
          let moveDistance; //移動的距離
          let averageDuration; //平均速率
          // 進場的keyframe
          function enterKeyframes() {
            let framesArr = [];
            switch (direction) {
              case 'top':
                moveDistance = Math.abs(m4.s.animateEl.scrollHeight - m4.clientHeight);
                averageDuration = parseInt(m4.s.nowDuration / (moveDistance * 2)) * m4.s.animateEl.scrollHeight;
                framesArr = [{ transform: `translate3d(0,${m4.clientHeight}px,0)` }, { transform: `translate3d(0,calc(${m4.clientHeight}px - 100%),0)` }];
                break;
              case 'right':
                moveDistance = Math.abs(m4.s.animateEl.scrollWidth - m4.clientWidth);
                averageDuration = parseInt(m4.s.nowDuration / (moveDistance * 2)) * m4.s.animateEl.scrollWidth;
                framesArr = [{ transform: `translate3d(-100%,0,0)` }, { transform: `translate3d(0,0,0)` }];
                break;
              case 'bottom':
                moveDistance = Math.abs(m4.s.animateEl.scrollHeight - m4.clientHeight);
                averageDuration = parseInt(duration / (moveDistance * 2)) * m4.s.animateEl.scrollHeight;
                framesArr = [{ transform: `translate3d(0,-100%,0)` }, { transform: `translate3d(0,0,0)` }];
                break;
              case 'left':
                moveDistance = Math.abs(m4.s.animateEl.scrollWidth - m4.clientWidth);
                averageDuration = parseInt(duration / (moveDistance * 2)) * m4.s.animateEl.scrollWidth;
                framesArr = [{ transform: `translate3d(${m4.clientWidth}px,0,0)` }, { transform: `translate3d(calc(-100% + ${m4.clientWidth}px),0,0)` }];
                break;
            }
            return framesArr;
          }
          // 跑馬燈範圍高度大於內容高度或是方向為左/右時
          if (m4.clientHeight > m4.s.animateEl.scrollHeight || direction === 'left' || direction === 'right') {
            m4.s.animateEl.style.transform = `${enterKeyframes()[0].transform}`;
            const enterAnimate = m4.s.animateEl.animate(enterKeyframes(), {
              duration: averageDuration,
              fill: 'forwards',
            });
            enterAnimate.finished.then(() => {
              m4.s.animation = m4.s.animateEl.animate(keyframes(m4).animate1, {
                duration: m4.s.nowDuration,
                iterations: Infinity,
              });
            });
          }
          if (m4.s.animation) {
            m4.s.animation.ready.then(() => {
              m4.s.animateEl.classList.add('ready');
            });
          }
          break;
        case 'endStop':
          m4.s.animateEl.style.transform = `${keyframes(m4).animate1[0].transform}`;
          m4.s.animation = m4.s.animateEl.animate(keyframes(m4).animate1, {
            duration: m4.s.nowDuration,
            fill: 'forwards',
          });
          break;
      }
      m4.s.animateEl.classList.add('start');
      if (autoplay === false) {
        m4.s.animateEl.classList.remove('start');
        m4.s.animation.cancel();
      }
    }
    clearTimeout(playDelay);
    playDelay = setTimeout(
      () => {
        animateInit();
      },
      autoplay ? autoplay : 0,
    );

    // mouseenter/leave Event
    m4.addEventListener('mouseenter', function () {
      if (m4.s.animation && pauseOnMouseenter && m4.s.animation.playState === 'running') {
        m4.pause();
      }
    });
    m4.addEventListener('mouseleave', function () {
      if (m4.s.animation && pauseOnMouseenter && m4.s.animation.playState === 'paused') {
        m4.play();
      }
    });
  }
  #event() {
    const m4 = this;
    function updateDuration() {
      const startTime = m4.s.animation.startTime;
      const cloneStartTime = m4.s.cloneAnimation ? m4.s.cloneAnimation.startTime : null;
      m4.s.nowDuration = getDuration(m4);
      m4.s.animation.cancel();
      if (m4.s.cloneAnimation) {
        m4.s.cloneAnimation.cancel();
      }
      m4.s.animation = m4.s.animateEl.animate(keyframes(m4).animate1, {
        duration: m4.s.nowDuration,
        iterations: Infinity,
      });
      m4.s.animation.startTime = startTime;
      if (m4.s.cloneAnimation) {
        m4.s.cloneAnimation = m4.s.cloneAnimateEl.animate(keyframes(m4).animate2, {
          duration: m4.s.nowDuration,
          delay: -m4.s.nowDuration / 2,
          iterations: Infinity,
        });
        m4.s.cloneAnimation.startTime = cloneStartTime;
      }
    }
    window.addEventListener('resize', debounce(updateDuration));
  }
  play() {
    this.s.animation.play();
    this.s.animateEl.classList.add('start');
    if (this.s.options.continual) {
      this.s.cloneAnimation.play();
      this.s.cloneAnimateEl.classList.add('start');
    }
  }
  pause() {
    this.s.animation.pause();
    if (this.s.options.continual) {
      this.s.cloneAnimation.pause();
    }
  }
}

// define custom element
if (!customElements.get('marquee-el')) {
  customElements.define('marquee-el', Marquee4);
}

export default Marquee4;
