import SHARED from './../shared/shared';
import { isString, isElementExist, getElement, getAllElements } from './../shared/utils';

const interaction = (scrollElement, elements) => {
  const viewHeight = scrollElement === window ? scrollElement.innerHeight : scrollElement.getBoundingClientRect().height;
  const viewTop = scrollElement === window ? 0 : scrollElement.getBoundingClientRect().top;

  elements.forEach(el => {
    const { class: className, delay, start, end, repeat, instance } = el.aost;

    const { top, bottom } = el.getBoundingClientRect();
    const startTrigger = viewHeight * (start / 100);
    const endTrigger = viewHeight * (end / 100);
    const isEntered = top - viewTop <= startTrigger && bottom - viewTop >= endTrigger;

    // in view
    if (isEntered && el.offsetParent) {
      setTimeout(() => {
        // instance.emit('enter', el);
        el.classList.add(className);
      }, delay);
    }
    // out of view
    else {
      const shouldRemove = (el.classList.contains(className) && repeat === 'down' && top - viewTop >= startTrigger) || (repeat === 'up' && bottom - viewTop <= endTrigger) || repeat === true;
      setTimeout(() => {
        // instance.emit('leave', el);
        if (shouldRemove) el.classList.remove(className);
      }, delay);
    }
  });
};

const detectRepeat = (repeatType, options) => {
  if (repeatType === 'up' || repeatType === 'down') {
    return repeatType;
  }

  if (repeatType !== null) {
    if (repeatType === 'true') {
      return true;
    } else if (repeatType === 'false') {
      return false;
    } else {
      return options.repeat;
    }
  }

  return options.repeat;
};

class Aost4 {
  constructor(el, options = {}) {
    this.__storage__ = {
      el,
      options,
    };

    this.#create();
  }

  #create() {
    const { el, options } = this.__storage__;
    if (!isString(el) || !isElementExist(el)) return;

    const { SETTINGS, EVENTS } = fesdDB.aost4;

    this.elements = getAllElements(el);
    this.options = Object.assign({}, SETTINGS, options);
    this.__events__ = Object.assign({}, EVENTS);

    if (this.options.on) {
      for (const [k, v] of Object.entries(this.options.on)) {
        this.__events__[k] = [v];
      }
    }

    this.#init();
  }

  #init() {
    const { elements, options } = this;
    const { scroller } = options;
    const scrollElement = scroller === window || !isElementExist(scroller) ? window : getElement(scroller);

    this.eventHandler = () => {
      interaction(scrollElement, elements);
    };

    elements.forEach(el => {
      el.aost = {};
      el.aost.class = el.getAttribute('data-aost-class') || options.class;
      el.aost.delay = parseInt(el.getAttribute('data-aost-delay')) || options.delay;
      el.aost.start = parseInt(el.getAttribute('data-aost-start')) || options.start;
      el.aost.end = parseInt(el.getAttribute('data-aost-end')) || options.end;
      el.aost.repeat = detectRepeat(el.getAttribute('data-aost-repeat'), options);
      el.aost.instance = this;
    });

    const { eventHandler } = this;
    eventHandler();
    scrollElement.aost = {};
    scrollElement.aost.eventHandler = eventHandler;
    scrollElement.addEventListener('scroll', scrollElement.aost.eventHandler, false);
  }

  destroy(removeShow) {
    const { elements, options } = this;
    if (!elements) return this;

    const { scroller } = options;
    const scrollElement = scroller === window || !isElementExist(scroller) ? window : getElement(scroller);

    if (scrollElement.aost) {
      scrollElement.removeEventListener('scroll', scrollElement.aost.eventHandler);
      delete scrollElement.aost;
    }

    elements.forEach(el => {
      if (!el.aost) return;
      const { class: className } = el.aost;
      if (removeShow) {
        el.classList.remove(className);
      }
      delete el.aost;
    });

    return this;
  }

  update(removeShow) {
    this.destroy(removeShow).#create();
  }
}

Object.assign(Aost4.prototype, SHARED);

export default Aost4;
